<template>
  <el-row :gutter="20" type="flex" class="home-row" justify="center">
    <el-col :span="!isRegistered ? 8 : 20">
      <el-card class="login_card">
        <h1 v-if="isRegistered">会员注册</h1>
        <h1 v-else>会员登录</h1>
        <el-form :model="dataForm" ref="dataForm"  v-if="!isRegistered" label-position="right" label-width="100px" style="transform: translateX(-7%);margin-top: 30px">
          <el-row  type="flex" justify="center">
            <el-col :span="20">
              <el-form-item label="用户名:">
                <el-input v-model="dataForm.username"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row  type="flex" justify="center">

            <el-col :span="20">
              <el-form-item label="密码:">
                <el-input v-model="dataForm.password" type="password"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row style="margin-top: 30px" v-if="!isRegistered">
          <el-col :span="12">
            <el-button class="login_button" type="primary" @click="loginThis">登录</el-button>
          </el-col>
          <el-col :span="12">
            <el-button class="login_button" @click="isRegistered=true;step=0" type="primary" plain>注册</el-button>
          </el-col>
        </el-row>
        <template v-if="isRegistered" class="register_template">
          <el-steps :active="step" align-center class="reg_steps">
            <el-step title="用户名"></el-step>
            <el-step title="选择机构" ></el-step>
            <el-step title="申请资料" ></el-step>
            <el-step title="资料确认" ></el-step>
            <el-step title="入会完成" ></el-step>
          </el-steps>
          <Page1 v-if="step===0" :dataForm="step1" :realMsgcode="realMsgcode"></Page1>
          <Page2 v-if="step===1" :dataForm="step2"></Page2>
          <Page3 v-if="step===2" :dataForm="step3" :email="step1.email" :mobile="step1.mobile"></Page3>
          <Page4 v-if="step===3" :step1="step1" :step2="step2" :step3="step3"></Page4>
          <div style="width: 1000px" v-if="step===4">
            <el-row type="flex" justify="center">

            </el-row>
          </div>
        </template>
        <div v-if="step===4" class="head-icon-row">
<!--          <img src="../../../assets/images/check.png" />-->
<!--          您的资料提交成功-->
        </div>
        <!-- <div class="text">
            我们的审核时效为5个工作日，审核状态将通过您提供的手机号以短信形式告知，也可登录账号查看审核进度，如有疑问，请联系学会办公室
        </div>
        <div class="text">021-00000000</div>
        <div class="text">邮箱：secretariat_ssa@163.com</div>
        <div class="text-blue margin-top-30" > 欢迎关注我们的微信公众号、了解行业资讯，请扫描学会企业微信，以便及时收到学会所发通知</div>
        <div class="qr-code">
            <img src="../../../assets/images/shshtroke-qr.png" />
        </div> -->
        <el-row>
          <el-col :span="12"><el-button type="primary" plain class="login_button" v-if="isRegistered&&step!==0&&step!==4" @click="step=step-1">上一步</el-button></el-col>
          <el-col :span="12"><el-button type="primary" class="login_button" v-if="isRegistered&&step!==4"  @click="next">下一步</el-button></el-col>
        </el-row>


        <!-- <el-button class="login_button" style="margin-top: 30px" v-if="isRegistered&&step===4" type="success" plain @click="registerThis">确认注册</el-button> -->
        <el-button class="login_button" v-if="isRegistered" type="text" size="mini" @click="isRegistered=false">已注册?返回登录</el-button>

      </el-card>
    </el-col>

  </el-row>


</template>

<script>

import Vue from 'vue'
import {register,login} from '@/http/request'
import {setCookie} from '../../../util/util.js';
import Page1 from '../components/page1'
import Page2 from '../components/page2'
import Page3 from '../components/page3'
import Page4 from '../components/page4'
export default {
  beforeMount () {
    Vue.prototype.$cookieStore = {
      setCookie,
    }},
  components:{Page1,Page2,Page3,Page4},
  data() {
    return {
      captchaPath: '',
      step1: {
        username:'',// 用户名
        mobile:'', // 电话
        email:'', // 邮箱
        password:'',// 密码
        passwordSure:'',// 确认密码
        msgCode:'',// 验证码
      },
      step1Des:[
        {key:'username', value:'用户名'},
        {key:'mobile', value:'电话'},
        {key:'email', value:'邮箱'},
        {key:'password', value:'密码'},
        {key:'passwordSure', value:'确认密码'},
        {key:'msgCode', value:'验证码'},
      ],
      step2:{
        zongHui:'上海卒中学会',
        checkList:[]
      },
      step2Des:[
        {key:'zongHui', value:'总会'},
        {key:'checkList', value:'分会'},
      ],
      step3:{
        type:'',
        name:'',
        minZu:'',
        zhuanYe:'',
        zhengJianLeiXing:'',
        zhiCheng:'',
        danWeiMingCheng:'',
        danWeiDiZhi:'',
        sex:'',
        zhengZhiMianMao:'',
        chuShengNianYue:'',
        zhengJianHaoMa:'',
        zhiWu:'',
        youBian:'',
        keShi:'',
        studyLog:'',
        chengGuo:'',
        zhaoPian:'',
      },
      step3Des:[
        {key:'type', value:'选择会员类型'},
        {key:'name', value:'输入姓名'},
        {key:'minZu', value:'选择民族'},
        {key:'zhuanYe', value:'输入专业'},
        {key:'zhengJianLeiXing', value:'选择证件类型'},
        {key:'zhiCheng', value:'选择职称'},
        {key:'danWeiMingCheng', value:'输入单位名称'},
        {key:'danWeiDiZhi', value:'输入单位地址'},
        {key:'sex', value:'选择性别'},
        {key:'zhengZhiMianMao', value:'输入政治面貌'},
        {key:'chuShengNianYue', value:'输入出生年月'},
        {key:'zhengJianHaoMa', value:'输入证件号码'},
        {key:'zhiWu', value:'输入职务'},
        {key:'youBian', value:'输入邮编'},
        {key:'keShi', value:'输入科室'},
        {key:'studyLog', value:'输入学习经历'},
        {key:'chengGuo', value:'输入主要学术或科普论著、研究成果'},
        {key:'zhaoPian', value:'上传照片'},
      ],
      dataForm:{
        username:'',
        password:''
      },
      isRegistered: false,
      step:0,
      visible:false,
      realMsgcode:''
    }
  },
  computed: {
    dataRule() {
      return {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  switch: {
    // dataForm(){
    //   console.log(this.dataForm)
    // }
  },
  created() {
  },
  methods: {
    toHuiYuan(){
      this.$router.push('/resister')
    },
    // 深拷贝
    deepCopy(data){
      if(data) {
        return JSON.parse(JSON.stringify(data))
      }else{
        return ''
      }
    },
    // // 下一步
    // next() {
    //   const infoList = [this.step1,this.step2,this.step3]
    //   this.$store.commit('setRegisterInfo', {step: this.step,data: this.deepCopy(infoList[this.step])});
    //   this.step++;
    // },
    // 非空校验
    next() {
      const step = this.step
      if(step === 0) {
        for(let i = 0; i<this.step1Des.length; i++) {
          if(!this.step1[this.step1Des[i].key]){
            this.$message.error(`请输入${this.step1Des[i].value}`)
            return
          }
          if(this.step1.password !== this.step1.passwordSure){
            this.$message.error('两次密码输入不一致');
            return
          }
          // if(this.$store.state.code) {
          //   if(this.step1.msgcode !== this.$store.state.code){
          //     this.$message.error('验证码不正确');
          //     return
          //   }
          // }else {
          //   this.$message.error('请获取验证码');
          //   return
          // }
        }
        this.step++;
      }
      if(step === 1) {
        // if(!this.step2.checkList.length){
        //   this.$message.error('请选择分会')
        //   return
        // }
        this.step++;
      }
      if(step === 2) {
        for(let i = 0; i<this.step3Des.length; i++) {
          if(!this.step3[this.step3Des[i].key]){
            this.$message.error(`请${this.step3Des[i].value}`)
            return
          }
        }
        this.step++;
      }
      if(step === 3) {
        register({...this.step1,...this.step2,...this.step3,fenHui:this.step2.checkList.join(',')}).then(({data}) => {
          if(data.code !== 0) {
            this.$message.error(data.msg)
            return
          }else{
            this.isRegistered=true
            this.$message.success('注册成功,请登录!')
            this.step++;
          }
        })
      }
      // this.step++;
    },
    getRegister() {

    },
     // 登录
    loginThis(){
      login(this.dataForm).then(({data}) => {
        if(data.code !== 0) {
          this.$message.error(data.msg)
          return
        }else{
          this.$store.commit('setUserInfo', data.data.userInfo)
          this.$store.commit('setToken', data.data.token)
          // localStorage.setItem('sw_token',data.data.token)
          // localStorage.setItem('sw_userInfo',JSON.stringify(data.data.userInfo))
          this.$cookieStore.setCookie( 'token' , data.data.token)
          this.$cookieStore.setCookie( 'userId' , data.data.userInfo.id)
          this.$cookieStore.setCookie( 'username' , data.data.userInfo.username)
          this.$router.push('/')
          this.$message.success('登录成功')
          return 
        }
      })
    }
  }
}
</script>
<style lang="scss">
.login_card {
      .head-icon-row{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:22px;
        font-weight: 500;
        color: #3FB743;
        margin-top: 20px;
        img{
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
    }
    border: 1px solid #409EFF
  /*position: absolute;*/
  /*top: 26%;*/
  /*left: 50%;*/
  /*width: auto;*/
  /*height: auto;*/
  /*transform: translateX(-50%);*/
  /*overflow-y: scroll;*/
  /*!*margin-bottom: 4000px;*!*/
  /*!*padding-bottom: 4000px;*!*/
}
.login_form{
  margin-top: 30px;
  /*width: 500px;*/
  border-right: 1px solid #eee;
  padding-right: 40px;
}
.login_form  .el-form-item{
  margin-left: 30px;
  width: 90%;
  /*line-height: 10px;*/
}
.login_button{
  position: relative;
  left: 20%;
  width: 60%;

}
.register_template{
  padding-top: 30px;
  /*width: 1000px;*/
 
}
.reg_steps{
  position: relative;
  width: 100%;
  margin:  30px auto;
}
.reg_row_col{
  /*padding-left: 5%;*/
}
.home-row{
  margin-top: 20px;
}
.register-item {
  float: left;
  /*width: 50%;*/
  box-sizing: border-box;
  padding: 0 30px 80px 30px;
  margin-top: 20px;
}
.resister-tips {
  font-size: 14px;
  line-height: 32px;
}
.color-blue {
  color: #014DA3;
}
.color-gray {
  color: #5f6468;
}
.panel-body {
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
}
.notice-info {
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
}
.text-center {
  text-align: center;
}
.one-p {
  font-size: 16px;
  line-height: 32px;
  text-align: justify;
  margin: 40px 0;
  /* text-indent: 32px; */
}
.font14 {
  font-size: 14px;
}

.color-red {
  color: #f9243f;
}
</style>
