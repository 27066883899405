<template>
  <div class="page3-row">
    <div class="select-card">
      请选择会员类型：
      <el-select v-model="dataForm.type" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="base-data-card">
      <el-row>
        <el-form :model="dataForm" ref="dataForm" label-width="160px">
          <el-col :span="19" class="reg_row_col">
            <el-col :span="12">
              <el-form-item label="姓名">
                <el-input v-model="dataForm.name"></el-input>
              </el-form-item>
              <el-form-item label="民族">
                <el-select  class="base-data-select" v-model="dataForm.minZu" placeholder="请选择">
                    <!-- <el-option
                    v-for="item in nationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    ></el-option> -->
                    <el-option value="请选择">请选择</el-option>
                    <el-option value="汉族">汉族</el-option>
                    <el-option value="蒙古族">蒙古族</el-option>
                    <el-option value="回族">回族</el-option>
                    <el-option value="藏族">藏族</el-option>
                    <el-option value="维吾尔族">维吾尔族</el-option>
                    <el-option value="苗族">苗族</el-option>
                    <el-option value="彝族">彝族</el-option>
                    <el-option value="壮族">壮族</el-option>
                    <el-option value="布依族">布依族</el-option>
                    <el-option value="朝鲜族">朝鲜族</el-option>
                    <el-option value="满族">满族</el-option>
                    <el-option value="侗族">侗族</el-option>
                    <el-option value="瑶族">瑶族</el-option>
                    <el-option value="白族">白族</el-option>
                    <el-option value="土家族">土家族</el-option>
                    <el-option value="哈尼族">哈尼族</el-option>
                    <el-option value="哈萨克族">哈萨克族</el-option>
                    <el-option value="傣族">傣族</el-option>
                    <el-option value="黎族">黎族</el-option>
                    <el-option value="傈僳族">傈僳族</el-option>
                    <el-option value="佤族">佤族</el-option>
                    <el-option value="畲族">畲族</el-option>
                    <el-option value="高山族">高山族</el-option>
                    <el-option value="拉祜族">拉祜族</el-option>
                    <el-option value="水族">水族</el-option>
                    <el-option value="东乡族">东乡族</el-option>
                    <el-option value="纳西族">纳西族</el-option>
                    <el-option value="景颇族">景颇族</el-option>
                    <el-option value="柯尔克孜族">柯尔克孜族</el-option>
                    <el-option value="土族">土族</el-option>
                    <el-option value="达斡尔族">达斡尔族</el-option>
                    <el-option value="仫佬族">仫佬族</el-option>
                    <el-option value="羌族">羌族</el-option>
                    <el-option value="布朗族">布朗族</el-option>
                    <el-option value="撒拉族">撒拉族</el-option>
                    <el-option value="毛南族">毛南族</el-option>
                    <el-option value="仡佬族">仡佬族</el-option>
                    <el-option value="锡伯族">锡伯族</el-option>
                    <el-option value="阿昌族">阿昌族</el-option>
                    <el-option value="普米族">普米族</el-option>
                    <el-option value="塔吉克族">塔吉克族</el-option>
                    <el-option value="怒族">怒族</el-option>
                    <el-option value="乌孜别克族">乌孜别克族</el-option>
                    <el-option value="俄罗斯族">俄罗斯族</el-option>
                    <el-option value="鄂温克族">鄂温克族</el-option>
                    <el-option value="德昂族">德昂族</el-option>
                    <el-option value="保安族">保安族</el-option>
                    <el-option value="裕固族">裕固族</el-option>
                    <el-option value="京族">京族</el-option>
                    <el-option value="塔塔尔族">塔塔尔族</el-option>
                    <el-option value="独龙族">独龙族</el-option>
                    <el-option value="鄂伦春族">鄂伦春族</el-option>
                    <el-option value="赫哲族">赫哲族</el-option>
                    <el-option value="门巴族">门巴族</el-option>
                    <el-option value="珞巴族">珞巴族</el-option>
                    <el-option value="基诺族">基诺族</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业">
                <el-input v-model="dataForm.zhuanYe"></el-input>
              </el-form-item>
              <el-form-item label="证件类型">
                <el-select  class="base-data-select" v-model="dataForm.zhengJianLeiXing" placeholder="请选择">
                    <el-option
                    v-for="item in certificatesOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="职称">
                <el-select class="base-data-select" v-model="dataForm.zhiCheng" placeholder="请选择">
                    <el-option
                    v-for="item in titleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="移动电话">
                <el-input disabled v-model="mobile"></el-input>
              </el-form-item>
              <el-form-item label="单位名称">
                <el-input v-model="dataForm.danWeiMingCheng"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio-group v-model="dataForm.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="政治面貌">
                <el-input v-model="dataForm.zhengZhiMianMao"></el-input>
              </el-form-item>
              <el-form-item label="出生年月">
                <el-date-picker
                  style="width:380px"
                  value-format='yyyy-MM-dd'
                  v-model="dataForm.chuShengNianYue"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input v-model="dataForm.zhengJianHaoMa"></el-input>
              </el-form-item>
              <el-form-item label="职务">
                <el-input v-model="dataForm.zhiWu"></el-input>
              </el-form-item>
              <el-form-item label="邮编">
                <el-input v-model="dataForm.youBian"></el-input>
              </el-form-item>
              <el-form-item label="科室">
                <el-input v-model="dataForm.keShi"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="邮箱">
                    <el-input disabled v-model="email"></el-input>
                </el-form-item>
                <el-form-item label="单位地址">
                    <el-input v-model="dataForm.danWeiDiZhi"></el-input>
                </el-form-item>
                <el-form-item label="主要学习经历">
                    <el-input  type="textarea" :rows="5"  v-model="dataForm.studyLog"></el-input>
                </el-form-item>
                <el-form-item label="主要学术或科普论著、研究成果">
                    <el-input type="textarea" :rows="5" v-model="dataForm.chengGuo"></el-input>
                </el-form-item>
            </el-col>
          </el-col>
        </el-form>
        <el-col :span="5" class="reg_row_right">
            <el-upload
                class="avatar-uploader"
                :action="url"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError">
                <img v-if="dataForm.zhaoPian" :src="dataForm.zhaoPian" class="avatar">
                <img class="avatar" v-else src="../../../assets/images/photo.png" />
            </el-upload>
            <div style="padding:15px 0;color:red">注：点击图片上传照片</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    email: {
      type: String,
      default: () => {
        return '';
      }
    },
    mobile: {
      type: String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    return {
      options: [
        { value: "学生会员", label: "学生会员" },
        { value: "普通会员", label: "普通会员" },
        { value: "资深会员", label: "资深会员" },
        { value: "名誉会员", label: "名誉会员" },
        { value: "单位（事业/社团）会员", label: "单位（事业/社团）会员" },
        { value: "单位（企业）会员", label: "单位（企业）会员" }
      ],
      nationOptions:[],//民族列表
      certificatesOptions:[
        { value: "身份证", label: "身份证" },
        { value: "护照", label: "护照" },
      ],//证件类型列表
      titleOptions:[
        { value: "初级", label: "初级" },
        { value: "中级", label: "中级" },
        { value: "副高级", label: "副高级" },
        { value: "正高级", label: "正高级" },
        { value: "其他", label: "其他" },
      ],//职称列表
      url:'',
    };
  },
  methods:{
      handleAvatarSuccess(res){
        if(res.code === 0) {
          this.dataForm.zhaoPian = res.data.src
        }else{
          this.$message.error(res.msg)
        }
      },
      handleAvatarError() {
        this.$message.error('上传失败')
      }
  },
  mounted() {
    this.url = `${process.env.VUE_APP_BASE_URL}/sys/oss/upload`
  }
};
</script>

<style lang="scss">
.page3-row {
  .select-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border-bottom: 1px solid rgb(221, 219, 219);
  }
  .base-data-card {
    padding: 70px 80px;
  }
  .base-data-select{
      width: 100%;
  }
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 173px;
    line-height: 173px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 173px;
    display: block;
  }
  .reg_row_right{
      padding: 0  20px;
  }
}
</style>