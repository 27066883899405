<template>
  <div class="page4-row">
      <div class="base-data-card">
      <el-row>
        <el-form :model="step1" ref="step1" label-width="160px">
          <el-col :span="19" class="reg_row_col">
            <el-col :span="12">
              <el-form-item label="用户名：">
                <span>{{this.step1.username}}</span>
              </el-form-item>
              <el-form-item label="性别：">
                <span>{{['','男','女'][this.step3.sex]}}</span>
              </el-form-item>
              <el-form-item label="证件类型：">
                <span>{{this.step3.zhengJianLeiXing}}</span>
              </el-form-item>
              <el-form-item label="政治面貌：">
                <span>{{this.step3.zhengZhiMianMao}}</span>
              </el-form-item>
              <el-form-item label="移动电话：">
                <span>{{this.step1.mobile}}</span>
              </el-form-item>
              
              <el-form-item label="总会：">
                <span>{{this.step2.zongHui}}</span>
              </el-form-item>
              <el-form-item label="职务：">
                <span>{{this.step3.zhiWu}}</span>
              </el-form-item>
              <el-form-item label="单位名称：">
                <span>{{this.step3.danWeiMingCheng}}</span>
              </el-form-item>
               <el-form-item label="邮编：">
                <span>{{this.step3.youBian}}</span>
              </el-form-item>
              <el-form-item label="专业：">
                <span>{{this.step3.zhuanYe}}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="会员类型：">
                <span>{{this.step3.type}}</span>
              </el-form-item>
               <el-form-item label="姓名：">
                <span>{{this.step3.name}}</span>
              </el-form-item>
              <el-form-item label="出生年月：">
                <span>{{this.step3.chuShengNianYue}}</span>
              </el-form-item>
              <el-form-item label="证件号码：">
                <span>{{this.step3.zhengJianHaoMa}}</span>
              </el-form-item>
              <el-form-item label="民族：">
                <span>{{this.step3.minZu}}</span>
              </el-form-item>
              <el-form-item label="邮箱：">
                    <span>{{this.step1.email}}</span>
              </el-form-item>
              <el-form-item label="分会：">
                <span>{{this.step2.checkList.join(',')}}</span>
              </el-form-item>
              <el-form-item label="职称：">
                <span>{{this.step3.zhiCheng}}</span>
              </el-form-item>
              <el-form-item label="科室：">
                <span>{{this.step3.keShi}}</span>
              </el-form-item>
              
            </el-col>
            <el-col :span="24">
                
                <el-form-item label="单位地址：">
                    <span>{{this.step3.danWeiDiZhi}}</span>
                </el-form-item>
                <el-form-item label="主要学习经历：">
                    <span>{{this.step3.studyLog}}</span>
                </el-form-item>
                <el-form-item label="主要学术或科普论著、研究成果：">
                    <span>{{this.step3.chengGuo}}</span>
                </el-form-item>
            </el-col>
          </el-col>
        </el-form>
        <el-col :span="5" class="reg_row_right1">
            <img style="width:120px, height:170px" :src="step3.zhaoPian" alt=""/>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="text">
        我们的审核时效为5个工作日，审核状态将通过您提供的手机号以短信形式告知，也可登录账号查看审核进度，如有疑问，请联系学会办公室
    </div>
    <div class="text">021-00000000</div>
    <div class="text">邮箱：secretariat_ssa@163.com</div>
    <div class="text-blue margin-top-30" > 欢迎关注我们的微信公众号、了解行业资讯，请扫描学会企业微信，以便及时收到学会所发通知</div>
    <div class="qr-code">
        <img src="../../../assets/images/shshtroke-qr.png" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    step1: {
      type: Object,
      default: () => {
        return {};
      }
    },
    step2: {
      type: Object,
      default: () => {
        return {};
      }
    },
    step3: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
}
</script>

<style lang="scss">
.page4-row{
    .head-icon-row{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:22px;
        font-weight: 500;
        color: #3FB743;
        img{
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
    }
    .text{
        text-align: center;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
        margin-top: 10px;
        font-weight: 500;
    }
    .text-blue{
        text-align: center;
        font-size: 16px;
        color: #014DA3;
        line-height: 30px;  
    }
    .margin-top-30{
        margin-top: 30px;
    }
    .qr-code{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        img{
            width: 90px;
            height: 91px;
        }
    }
}
.reg_row_right1{
   width:120px;
    height:170px;
    border: 1px solid #409EFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>