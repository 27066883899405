<template>
  <div class="page-2-row">
      <div class="search-card">
<!--          <input class="search-input" />-->
<!--          <div class="search-button">搜索</div>-->
      </div>
      <div class="item-list-row">
          <div class="item-list-title">总会</div>
          <div class="item-list-card">
              <div v-for="item in 1" :key="item" class="item-card">
                  <el-radio v-model="dataForm.zongHui"  disabled label="上海卒中学会" >上海卒中学会</el-radio>
              </div>
          </div>
          <div class="clear"></div>
          <div class="item-list-title">分会</div>
          <div class="item-list-card">
                <el-checkbox-group  v-model="dataForm.checkList" @change="onChange">
                    <el-checkbox class="item-card" v-for="item in checkboxData" :label="item" :key="item">{{item}}</el-checkbox>
                </el-checkbox-group>
          </div>
          <div class="clear"></div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        dataForm: {
            type: Object,
            default: () => {
                return {
                    zongHui:'上海卒中学会',
                    checkbox:[]
                }
            }
        }
    },
    data(){
        return{
            checkboxData:['上海卒中学会神经内科分会','上海卒中学会神经外科分会','上海卒中学会心血管内科分会','上海卒中学会护理学分会','上海卒中学会青年理事会']

        }
    },
    methods:{
        //多选时 选中的选项更改时触发的回调
        onChange(){
            
        }
    }
}
</script>

<style lang="scss">
.page-2-row{
    .search-card{
        display: flex;
        width: 500px;
        margin: 0 auto;
        .search-input {
            flex: 1;
            border: 1px solid #9c9797;
            border-right: none;
            height: 45px;
            padding: 5px 8px;
            box-sizing: border-box;
            font-size: 16px;
        }
        .search-button{
            height: 45px;
            width: 100px;
            color: #fff;
            font-size: 16px;
            background-color: #014DA3;
            cursor: pointer;
            line-height: 45px;
            text-align: center;
        }
    }
    .item-list-row{
        margin:40px auto;
        width: 800px;
    }
    .item-list-title{
        color: #004DA2;
        border-bottom: 2px solid #004DA2;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
        display: inline-block;
    }
    .clear{
        clear: both;
    }
    .item-card{
        float: left;
        margin-right: 20px;
        width: 40%;
        margin: 0 20px 20px 0;
        background-color: #FBFBFB;
        padding: 8px 15px;
        font-size: 14px;
    }
}
</style>