<template>
  <div>
    <el-row>
      <el-col :span="12" class="reg_row_col">
        <el-form :model="dataForm" ref="dataForm" label-width="80px" class="login_form">
          <el-form-item label="用户名:">
            <el-input v-model="dataForm.username"></el-input>
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input v-model="dataForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:">
            <el-input v-model="dataForm.email"></el-input>
          </el-form-item>
          <el-form-item label="密码:">
            <el-input v-model="dataForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:">
            <el-input v-model="dataForm.passwordSure" type="password"></el-input>
          </el-form-item>
          <el-form-item label="验证码:">
            <el-row>
              <el-col :span="16">
                <el-input v-model="dataForm.msgCode" type="password"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button :disabled="disableGetCode || !this.dataForm.mobile" style="margin-left: 20px; width:112px" @click="getMsgCode" type="primary" plain>{{this.disableGetCode? `${this.count} s` : '获取验证码'}}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <el-button
          type="text"
          class="login_button"
          style="margin-bottom: 10px;margin-top: 20px"
          @click="visible = true"
        >*点击下一步即默认为已阅读并同意入会须知(点击查看)</el-button>
        <el-dialog :visible.sync="visible">
          <div class="notice-info" style="height: 592px;">
            <h1 class="text-center">关于发展会员的通知</h1>
            <p
              class="one-p"
            >上海卒中学会（Shanghai Stroke Association，SSA）是由本市神经内科、神经外科、心血管内科、护理学、急症救治和慢病预防专业人士自愿组成的社会团体。本学会已经上海民政局批准成立为一级学会。本会的宗旨是团结卒中防治领域临床医学和科学技术工作者，促进基础医学、预防医学与临床医学以及中西医学的融合，提高本市脑卒中防治水平。本市从事神经内、外科、心血管内科、中医学、护理专业、急救和慢病防治专业人员都可以自愿申请加入本会，成为会员。</p>
            <p class="one-p">
              <b>本会会员享有下列权利：</b>
              <br />（一）本会的选举权、被选举权和表决权；
              <br />（二）参加本会的活动权；
              <br />（三） 获得本会服务的优先权；
              <br />（四） 对本会的章程、规章制度等享有知情权；
              <br />（五） 提议案权、建议权和监督权；
              <br />（六） 入会自愿、退会自由；
              <br />
              <br />
              <b>本会会员应履行下列义务：</b>
              <br />（一） 积极参与本会活动；
              <br />（二） 遵守本会的章程；
              <br />（三） 执行本会的决议；
              <br />（四） 维护本会的合法权益；
              <br />（五） 完成本会交办的工作；
              <br />（六） 向本会反映情况，提供有关资料；
              <br />
              <br />凡是自愿加入本会者请按照要求填写会员信息表并在“申请人签字”一栏完成书写格式签字，两种方式可供大家选择：（电子或纸质版本）
              <br />1. 将已填完的会员信息表打印并签字，扫描以PDF格式发送至指定邮箱：secretariat_ssa@ 163.com；
              <br />2. 将已填完的会员信息表打印并签字，快递至地址：上海市静安区南京西路1486号东海广场3号楼617室 上海卒中学会秘书长办公室 邮编：200040 收件人：屠文芳13564614281
              <br />
              <br />热烈欢迎各位同仁踊跃报名，感谢大家参与与支持!
            </p>
            <p class="one-p" style="text-align: right;">
              上海卒中学会秘书长办公室
              <br />2019年4月
            </p>
            <p class="color-red font14">注：按照章程个人会费50元／年，鉴于本会初创，目前申请加入本会者第一年不收取会费</p>
          </div>
        </el-dialog>
      </el-col>

      <el-col :span="12" class="reg_row_col">
        <div class="register-item resister-tips">
          <b class="color-blue">条例法规</b>
          <br />
          <!--                  <a href="" class="color-gray">协会会员条例</a><br>-->
          <el-button type="text" @click="toHuiYuan" style="color: #555555">协会会员条例</el-button>
          <br />
          <b class="color-blue">入会问答</b>
          <br />

          <a href="forgetPasw.aspx" class="color-gray">如何找回登录密码?</a>
          <br />
          <b class="color-blue">联系方式</b>
          <br />
          <p class="color-gray">
            会员服务：021-00000000
            <br />详细地址：上海市南京西路1486号东海广场3号楼617室 上海卒中学会—秘书办公处
            <br />邮编：200000
            <br />邮箱：secretariat_ssa@163.com
            <br />
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>  
import {getMsgCode} from '@/http/request'
export default {
  name: "Page1",
  props: {
    dataForm: {
      type: Object,
      default: () => {
          return {}
      }
    },
    realMsgcode: {
      type: String,
      default: () => {
          return ''
      }
    },
  },
  data() {
    return {
      visible: false,
      disableGetCode:false,
      count:'',
      timer: null
    };
  },
  mounted() {
    // console.log(22222,this.$store.state.userInfo);
    // this.$store.commit('mutationTest', '1')
  },
  methods: {
    toHuiYuan() {},
    // 获取验证码
    getMsgCode() {
      this.getCode()
      // let that = this
      getMsgCode(this.dataForm.mobile).then((res) => {
        if(res.data.code === 0){
          this.$message.success('验证码已经发送到您手机上')
        }else{
          this.$message.error(res.data.msg)
        }
      })
      
    },
    getCode(){
     const TIME_COUNT = 60;
     if (!this.timer) {
       this.count = TIME_COUNT;
       this.disableGetCode = true;
       this.timer = setInterval(() => {
       if (this.count > 0 && this.count <= TIME_COUNT) {
         this.count--;
        } else {
         this.disableGetCode = false;
         clearInterval(this.timer);
         this.timer = null;
        }
       }, 1000)
      }
    } 
  },
};
</script>

<style>
</style>